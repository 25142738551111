import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import NewPaybarFieldsetHeading from './newPaybarFieldsetHeading'
import { Caption, TextNode } from '../textNodes'
import { FormInput } from '../form'
import NewPaybarTelegramInput from './newPaybarTelegramInput'

class NewPaybarPersonalFieldsetGiftSection extends Component {
  get heading() {
    const { type, product } = this.props
    if (type === 'company') return I18n.t(`newPaybar.${product}.participant.${type}`)

    return I18n.t('newPaybar.gift.presentee')
  }

  render(props) {
    const presenteeNameLabel = props.hasFullPresenteeName ? I18n.t('newPaybar.labels.fullName') :
      I18n.t('newPaybar.gift.presenteeName')

    return (
      <div class={ cx('newPaybarPersonalFieldset-section', { noTopMargin: !props.hasHeading }) }>
        { props.hasHeading &&
          <NewPaybarFieldsetHeading text={ this.heading } />
        }

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { presenteeNameLabel }
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="presenteeName"
            type="text"
            required
            onValidityChange={ props.onInputValidityChange }
            onInput={ props.onInput }
            value={ props.rememberedFields.presenteeName }
            initialValue={ props.rememberedFields.presenteeName }
            ref={ ref => props.fieldRefs.presenteeName = ref } />
        </label>

        <label
          className={ cx('newPaybarPersonalFieldset-row', { has__caption: props.hasCaption }) }>

          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.gift.presenteeEmail') }
          </TextNode>
          <div className="newPaybarPersonalFieldset-inputWithCaption">
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeEmail"
              type="email"
              required
              onValidityChange={ props.onInputValidityChange }
              onInput={ props.onInput }
              value={ props.rememberedFields.presenteeEmail }
              initialValue={ props.rememberedFields.presenteeEmail }
              ref={ ref => props.fieldRefs.presenteeEmail = ref } />

            { props.hasCaption && (
              <Caption html={ I18n.t('newPaybar.course.companyOtherEmailCaption') } />
            ) }
          </div>
        </label>

        { props.hasTel &&
          <label className="newPaybarPersonalFieldset-row has__inlineCaption">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.tel') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="presenteeTel"
                type="tel"
                required
                onInput={ props.onInput }
                onValidityChange={ props.onInputValidityChange }
                initialValue={ props.rememberedFields.presenteeTel }
                ref={ ref => props.fieldRefs.presenteeTel = ref } />
              <Caption html={ I18n.t('newPaybar.school.telCaption') } />
            </div>
          </label>
        }

        { props.hasTelegram &&
          <NewPaybarTelegramInput
            name="presenteeTelegram"
            key="presenteeTelegram"
            onInput={ props.onInput }
            onValidityChange={ props.onInputValidityChange }
            initialValue={ props.rememberedFields.presenteeTelegram }
            ref={ ref => props.fieldRefs.presenteeTelegram = ref } />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    type: newPaybar.type,
  }
}

export default connect(mapStateToProps)(NewPaybarPersonalFieldsetGiftSection)

import { urlToParamsArray } from 'lib/urlHelpers'
import { getTypeFromUrl } from './typesUrlHelper'
import getAvailableTabs from './getAvailableTabs'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import getCompanyTypeScreen from './helpers/getCompanyTypeScreen'
import hasCompanyPurchaseSupport from './helpers/hasCompanyPurchaseSupport'

const getSelfState = ({ isUserAuthenticated, subscription, prices }) => {
  let screen = 'purchase'

  const isSubscribed = !!subscription.id || isSubscriptionCancelled(subscription)
  const canUpgrade = !!prices.specialOffer
  const shouldDisplayUpgrade = canUpgrade && !isSubscribed

  if (!isUserAuthenticated) screen = 'initial'
  if (isSubscribed) screen = 'subscription'

  return {
    isVisible: true,
    type: shouldDisplayUpgrade ? 'upgrade' : 'self',
    screen,
  }
}

const getGiftState = ({ isUserAuthenticated }) => {
  return {
    isVisible: true,
    type: 'gift',
    screen: isUserAuthenticated ? 'purchase' : 'initial',
    activeBasementItem: undefined,
  }
}

const getUpgradeState = ({ isUserAuthenticated }) => {
  if (!isUserAuthenticated) return {}

  return {
    isVisible: true,
    type: 'upgrade',
    screen: 'purchase',
  }
}

const getCompanyState = ({ isUserAuthenticated, product, subscription }) => {
  const shouldDisplayPaybar = hasCompanyPurchaseSupport(product) ? true : !isUserAuthenticated
  if (!shouldDisplayPaybar) return {}

  return {
    isVisible: true,
    type: 'company',
    screen: getCompanyTypeScreen({ product, isUserAuthenticated, subscription }),
  }
}

const TYPE_STATES_MAP = {
  self: getSelfState,
  gift: getGiftState,
  company: getCompanyState,
  upgrade: getUpgradeState,
}

const getLicenseState = (props, type) => {
  const typeState = type ? TYPE_STATES_MAP[type](props) : { isVisible: true, type: 'self' }

  return {
    ...typeState,
    activeBasementItem: 'license',
  }
}

const getDerivedStateFromUrl = (props) => {
  const params = urlToParamsArray(window.location.search)
  const type = getTypeFromUrl()
  const availableTabs = getAvailableTabs(props)

  const shouldDisplayLicense = !!params.find(param => param.name === 'license')
  if (shouldDisplayLicense) return getLicenseState(props, type)

  const shouldDisplayUpgrade = type === 'self' && availableTabs.includes('upgrade')
  if (shouldDisplayUpgrade) return getUpgradeState(props)

  const canHandleType = availableTabs.includes(type)

  if (!type || !canHandleType) return {}

  const getState = TYPE_STATES_MAP[type]

  return getState ? getState(props) : {}
}

export default getDerivedStateFromUrl

import hasCompanyPurchaseSupport from './helpers/hasCompanyPurchaseSupport'

const getAvailableTabs = ({ subscription = {}, prices = {}, hasCompanyTabOnly }) => {
  if (hasCompanyTabOnly) return ['company']

  const canUpgrade = !!prices.specialOffer
  const shouldDisplayCompanyTab = subscription.subscription_type === 'company' ||
    hasCompanyPurchaseSupport(subscription.product)

  return [
    canUpgrade ? 'upgrade' : 'self',
    'gift',
    shouldDisplayCompanyTab ? 'company' : '',
  ].filter(tab => tab)
}

export default getAvailableTabs

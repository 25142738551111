import { Component } from 'preact'
import cx from 'classnames'

import { Heading3 } from '../textNodes'

export default class NewPaybarParticipantTypeSwitch extends Component {
  render() {
    const { participantType, hasSelfSubscription, onSetSelf, onSetOther } = this.props
    const isSelf = participantType === 'self'

    const switcherItems = [
      {
        title: I18n.t('newPaybar.course.participantTypeSwitch.self'),
        isActive: isSelf && !hasSelfSubscription,
        isDisabled: hasSelfSubscription,
        onClick: hasSelfSubscription ? undefined : onSetSelf,
      },
      {
        title: I18n.t('newPaybar.course.participantTypeSwitch.other'),
        isActive: !isSelf || hasSelfSubscription,
        onClick: onSetOther,
      },
    ]

    return (
      <div className="newPaybarSwitch">
        <Heading3 className="newPaybarSwitch-heading">
          { I18n.t('newPaybar.course.participant.company') }
        </Heading3>
        <div className="newPaybarSwitch-selector">
          { switcherItems.map(({ title: __html, isActive, isDisabled, onClick }) => (
          <span
            className={ cx('newPaybarSwitch-selectorItem', { is__active: isActive, is__disabled: isDisabled }) }
            onClick={ onClick }
            dangerouslySetInnerHTML={{ __html }} />
          ))}
        </div>
      </div>
    )
  }
}
